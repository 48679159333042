import React, { useState } from 'react';
import './SettingsPage.css';

function SettingsPage() {
    const [notifications, setNotifications] = useState({
        emailNotifications: true,
        smsNotifications: false,
    });

    const handleChange = (e) => {
        const { name, type, checked } = e.target;
        if (type === 'checkbox') {
            setNotifications((prev) => ({ ...prev, [name]: checked }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Notifications Updated:', notifications);
    };

    return (
        <div className="settings-page">
            <h1>Settings</h1>
            <form onSubmit={handleSubmit}>
                <section className="notification-settings">
                    <h2>Notifications</h2>
                    <label>
                        <input
                            type="checkbox"
                            name="emailNotifications"
                            checked={notifications.emailNotifications}
                            onChange={handleChange}
                        />
                        Email Notifications
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="smsNotifications"
                            checked={notifications.smsNotifications}
                            onChange={handleChange}
                        />
                        SMS Notifications
                    </label>
                </section>
                <button type="submit">Save Changes</button>
            </form>
        </div>
    );
}

export default SettingsPage;
