import React from 'react';
import DonationCard from '../DonationCard/DonationCard';
import './CampaignList.css';

function CampaignList({ campaigns }) {
    return (
        <div className="campaign-list">
            {campaigns.map(campaign => (
                <DonationCard key={campaign.id} campaign={campaign} />
            ))}
        </div>
    );
}

export default CampaignList;
