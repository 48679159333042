import React, { useState, useEffect } from 'react';
import DonationCard from '../../components/DonationCard/DonationCard';
import { useAuth } from '../../context/AuthContext';
import './UserCampaignPage.css';
import { API_URL } from '../../config/Constants';

function UserCampaignsPage() {
    const { currentUser } = useAuth(); // Get current user info
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserCampaigns = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/campaigns/user`, {
                    headers: {
                        // Include authorization token if needed
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Could not fetch campaigns');
                }
                const data = await response.json();
                setCampaigns(data);
            } catch (error) {
                console.error(error.message);
            } finally {
                setLoading(false);
            }
        };

        if (currentUser) {
            fetchUserCampaigns();
        }
    }, [currentUser]);

    if (!currentUser) {
        return <div>Please log in to see your campaigns.</div>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!campaigns.length) {
        return <div>You haven't created any campaigns yet.</div>;
    }

    return (
        <div className='my-campaigns-page'>
            <h2>Your Campaigns</h2>
            <div className="campaigns-grids">
                {campaigns.map(campaign => (
                    <DonationCard key={campaign.id} campaign={campaign} />
                ))}
            </div>
        </div>
    );
}

export default UserCampaignsPage;
