import React, { useState } from 'react';
import './RegisterForm.css';

function RegisterForm({ onRegister }) {
    const [registerData, setRegisterData] = useState({
        name: '',
        email: '',
        password: '',
        userType: 'individual',
        agreeToPrivacyPolicy: false
    });

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;
        setRegisterData( prevState => ({
            ...prevState, [name]: type === 'checkbox' ? checked : value 
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onRegister(registerData);
    };

    return (
        <div className="register-form-container">
            <form className="register-form" onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={registerData.name}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={registerData.email}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={registerData.password}
                    onChange={handleChange}
                    required
                />

                <div className="privacy-policy-agreement">
                  <input
                    type="checkbox"
                    id="agreeToPrivacyPolicy"
                    name="agreeToPrivacyPolicy"
                    checked={registerData.agreeToPrivacyPolicy}
                    onChange={handleChange}
                  />
                  <label htmlFor="agreeToPrivacyPolicy">
                    I agree to the <a href="/privacy" target="_blank">Privacy Policy</a>
                  </label>
                </div>

                {/* Additional fields can be added here */}

                <button type="submit" disabled={!registerData.agreeToPrivacyPolicy}>Register</button>

            </form>
        </div>
    );
}

export default RegisterForm;
