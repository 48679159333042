import React from 'react';
import './AboutPage.css';

function AboutPage() {
    return (
        <div className="about-page">
            <h1>About Harambee Hub</h1>
            <p>
                Welcome to Harambee Hub, the leading platform for community-based 
                fundraising. We are dedicated to empowering individuals and groups to 
                make a positive impact in their communities through effective crowdfunding.
            </p>
            <h2>Our Mission</h2>
            <p>
                Our mission is to provide an accessible, transparent, and reliable 
                platform for Tanzanians to fundraise for causes they are passionate about. 
                Whether it's for medical expenses, educational purposes, or community projects, 
                we are here to support and guide you through the process.
            </p>
            {/* Additional sections such as team information, history, etc., can be added here */}

            <section className="our-team">
                <h2>Our Team</h2>
                <p>
                    Meet the dedicated team behind Harambee Hub. Our diverse group 
                    of professionals brings a wealth of experience and passion to every 
                    project we support.
                </p>
                {/* Team member profiles, images, and bios could be added here */}
            </section>

            <section className="success-stories">
                <h2>Success Stories</h2>
                <p>
                    Discover the impactful stories of campaigns that have changed lives 
                    through Harambee Hub. From community projects to personal 
                    fundraisers, each story is a testament to the power of collective support.
                </p>
                {/* Success stories, possibly with images, quotes, or links to case studies */}
            </section>

            <section className="our-partnerships">
                <h2>Our Partnerships</h2>
                <p>
                    We collaborate with various organizations and businesses to maximize 
                    the impact of your campaigns. Our partners contribute in numerous ways, 
                    from providing resources to offering expertise.
                </p>
                {/* Information about partners, joint ventures, or endorsements */}
            </section>
        </div>
    );
}

export default AboutPage;
