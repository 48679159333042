import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import HomePage from './pages/HomePage/HomePage';
import UserProfilePage from './pages/UserProfilePage/UserProfilePage';
import CampaignPage from './pages/CampaignPage/CampaignPage';
import UserCampaignPage from './pages/CampaignPage/UserCampaignPage';
import AllCampaignsPage from './pages/CampaignPage/AllCampaignsPage';
import CreateCampaignPage from './pages/CampaignPage/CreateCampaignPage';
import EditCampaignPage from './pages/CampaignPage/EditCampaignPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import LoginPage from './pages/LoginPage/LoginPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import PrivacyPolicy from './pages/PrivacyPolicyPage/PrivacyPolicy';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import './App.css';
import Sidebar from './components/Navbar/Sidebar';

function App() {
  return (
    <Router>
      <AuthProvider>
      <div className="App">
        <Header />
        <div className="content-wrap">
          <Sidebar />
          <main className="main-content">

            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/campaign/:id" element={<CampaignPage />} />
              <Route path="/campaigns/user" element={<UserCampaignPage />} />
              <Route path="/campaigns" element={<AllCampaignsPage />} />
              <Route path="/campaigns/create" element={<CreateCampaignPage />} />
              <Route path="/campaigns/edit/:id" element={<EditCampaignPage />} />
              <Route path="/profile" element={<UserProfilePage />} />
              <Route path="/settings" element={<SettingsPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>

          </main>
        </div>

        <Footer />
      </div>
      </AuthProvider>
    </Router>
  );
}

export default App;