import React, { useState } from 'react';
import LoginForm from './LoginForm';
import { Link, useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../config/Constants';

function LoginPage() {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [status, setStatus] = useState(false);
    const { currentUser, login } = useAuth();

    if (currentUser) {
        navigate('/dashboard');
        return;
    }

    const handleLogin = async (loginData) => {
        setMessage(''); // Clear previous messages
        setStatus(false); // Reset status
        try {
            const response = await fetch(API_URL+'/users/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loginData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to login');
            }

            // Assuming the backend sends back a token upon successful login
            localStorage.setItem('token', data.token); // Save the token to local storage
            login(data.user);
            setMessage('Login successful!');
            setStatus(true);
            setTimeout(() => navigate('/dashboard'), 500); // Redirect to dashboard after login
        } catch (error) {
            setMessage(error.message || 'Login failed. Please try again.');
        }
    };

    return (
        <div className="login-page">
            <h2>Login to Your Account</h2>
            {message && <p className={status ? "success" : "error"}>{message}</p>}
            <LoginForm onLogin={handleLogin} />
            <p>
                Don't have an account? <Link to="/register">Register Now</Link>
            </p>
        </div>
    );
}

export default LoginPage;
