import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Placeholder for auth check logic (e.g., checking localStorage for a token)
        const user = localStorage.getItem('user'); // Assuming 'user' is stored in localStorage
        if (user) {
            setCurrentUser(JSON.parse(user));
        }
    }, []);

    const login = (userData) => {
        // Placeholder for login logic
        localStorage.setItem('user', JSON.stringify(userData));
        setCurrentUser(userData);
    };

    const logout = () => {
        // Placeholder for logout logic
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setCurrentUser(null);
        navigate('/login');
    };

    const value = {
        currentUser,
        login,
        logout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
