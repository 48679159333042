import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardPage.css';
import { useAuth } from '../../context/AuthContext';
import DonationCard from '../../components/DonationCard/DonationCard';
import { API_URL } from '../../config/Constants';

function DashboardPage() {
    const { currentUser } = useAuth();
    const [userCampaigns, setUserCampaigns] = useState([]);
    const [recentDonations, setRecentDonations] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    // Fetch user's campaigns
    useEffect(() => {
        const fetchUserCampaigns = async () => {
            if (currentUser) {
                try {
                    const response = await fetch(`${API_URL}/campaigns/user`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    });
                    const data = await response.json();
                    setUserCampaigns(data);
                } catch (error) {
                    console.error("Failed to fetch user's campaigns:", error);
                }
            }
        };

        fetchUserCampaigns();
    }, [currentUser, navigate]);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [navigate, token]);

    // Fetch user's recent donations
    useEffect(() => {
        const fetchRecentDonations = async () => {
            if (currentUser) {
                try {
                    const response = await fetch(`${API_URL}/donations/user/${currentUser._id}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    });
                    const data = await response.json();
                    setRecentDonations(data);
                } catch (error) {
                    console.error("Failed to fetch user's recent donations:", error);
                }
            }
        };

        fetchRecentDonations();
    }, [currentUser, navigate]);

    return (
        <div className="dashboard-container">
            <main className="dashboard-main">
                <header className="dashboard-header">
                    <h1>Welcome, {currentUser ? currentUser.name : 'User'}!</h1>
                </header>
                <div className="dashboard-summary">
                    {/* Placeholder for summary widgets or quick stats */}
                    <p>Total Campaigns: {userCampaigns.length}</p>
                    <p>Total Donations: {recentDonations.length}</p>
                </div>
                <div className="dashboard-content">
                    <section className="campaigns-overview">
                        <h2>Recent Campaigns</h2>
                        {userCampaigns && (
                            <div className="campaigns-grids">
                                {userCampaigns.slice(0, 3).map(campaign => (
                                    <DonationCard key={campaign.id} campaign={campaign} />
                                ))}
                            </div>
                        )}
                    </section>
                    <section className="donations-overview">
                        <h2>Recent Donations</h2>
                        {recentDonations && (
                            <ul>
                                {recentDonations.map(donation => (
                                    <li key={donation.id}>
                                        {new Intl.NumberFormat('en-TZ', {
                                        style: 'decimal',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                    }).format(donation.amount)} to {donation.campaign.title}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </section>
                </div>
            </main>
        </div>
    );
}

export default DashboardPage;