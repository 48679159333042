import React from 'react';
//import { Link } from 'react-router-dom';
import './Header.css';
import Navbar from '../Navbar/Navbar';

function Header() {
    return (
        <header className="site-header">
            <div className="container">
                <Navbar />
            </div>
        </header>
    );
}

export default Header;