import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../config/Constants';

function DonationForm({ campaignId }) {
    const { currentUser } = useAuth();
    const [donationDetails, setDonationDetails] = useState({
        amount: '',
        name: currentUser ? currentUser.name : '',
        paymentMethod: '',
        phone: '',
        userId: currentUser ? currentUser._id : null,
        accountNumber: '',
        isProcessing: false,
        feedback: ''
    });

    const handleInputChange = (e) => {
        setDonationDetails({ ...donationDetails, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDonationDetails(prevDetails => ({ ...prevDetails, isProcessing: true, feedback: '' }));

        try {
            const response = await fetch(`${API_URL}/donations/${campaignId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(donationDetails)
            });

            if (!response.ok) {
                throw new Error('Donation Failed');
            }
            
            setDonationDetails(prevDetails => ({ ...prevDetails, isProcessing: false, feedback: 'Thanks for your donation' }));
        } catch (error) {
            console.error("Error posting donation:", error);
        }      
    };

    return (
        <form onSubmit={handleSubmit} className="donation-form">
            <input 
                type="number" 
                name="amount" 
                value={donationDetails.amount} 
                onChange={handleInputChange} 
                placeholder="Donation Amount" 
                required 
            />
            <input 
                type="text" 
                name="name" 
                value={donationDetails.name} 
                onChange={handleInputChange} 
                placeholder="Your Name" 
            />
            <select 
                name="paymentMethod" 
                value={donationDetails.paymentMethod} 
                onChange={handleInputChange} 
                required
            >
                <option value="">Select Payment Method</option>
                <option value="Airtel">Airtel</option>
                <option value="Azampesa">Azampesa</option>
                <option value="Halopesa">Halopesa</option>
                <option value="Mpesa">Mpesa</option>
                <option value="Tigo">Tigopesa</option>
            </select>
            <input 
                type="text" 
                name="phone" 
                value={donationDetails.phone} 
                onChange={handleInputChange} 
                placeholder="Phone Number" 
                required 
            />
            {(donationDetails.paymentMethod === 'CRDB' || donationDetails.paymentMethod === 'NMB') && (
                <input 
                    type="text" 
                    name="accountNumber" 
                    value={donationDetails.accountNumber} 
                    onChange={handleInputChange} 
                    placeholder="Account Number" 
                    required 
                />
            )}
            <button type="submit" disabled={donationDetails.isProcessing}>
                {donationDetails.isProcessing ? 'Processing...' : 'Donate Now'}
            </button>
            {donationDetails.feedback && <p>{donationDetails.feedback}</p>}
        </form>
    );
}

export default DonationForm;