import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>Last updated: May 1, 2024</p>

      <section>
        <h2>Introduction</h2>
        <p>Welcome to HarambeeHub. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at info@harambeehub.com .</p>
      </section>

      <section>
        <h2>Information We Collect</h2>
        <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and services, when you participate in activities on the Services, or otherwise when you contact us.</p>
        <ul>
          <li><strong>Personal Information Provided by You.</strong> We collect names; phone numbers; email addresses; mailing addresses; job titles; usernames; passwords; contact preferences; contact or authentication data; billing addresses; debit/credit card numbers; and other similar information.</li>
          <li><strong>Payment Data.</strong> We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument.</li>
          <li><strong>Social Media Login Data.</strong> We provide you with the option to register using social media account details, like your Facebook, Twitter or other social media account.</li>
        </ul>
      </section>

      <section>
        <h2>How We Use Your Information</h2>
        <p>We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.</p>
        <ul>
          <li>To facilitate account creation and logon process.</li>
          <li>To send you marketing and promotional communications.</li>
          <li>To send administrative information to you.</li>
          <li>For other Business Purposes.</li>
        </ul>
      </section>

      <section>
        <h2>Share of Your Information</h2>
        <p>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</p>
      </section>

      <section>
        <h2>Your Privacy Rights</h2>
        <p>You have the right at any time to stop us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail.</p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>If you have any questions or comments about this policy, you may contact us through:</p>
        <p>+255 693 692 847<br />
        info@harambeehub.com<br />
        Mbezi Beach A, Dar es Salaam, Tz</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;