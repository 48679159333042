import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { useAuth } from '../../context/AuthContext';

function Navbar() {
    const { currentUser, logout } = useAuth();
    const [showDropdown, setShowDropdown] = useState(false);
    const location = useLocation();
    const dropdownRef = useRef(null);

    // Toggle dropdown open/close
    const toggleDropdown = () => setShowDropdown(prev => !prev);

    // Handle clicking outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    // Function to determine if the path is for an individual campaign page
    const isCampaignDetailPage = (pathname) => {
        // Check if path starts with '/campaigns/' and has an ID part
        const pathParts = pathname.split('/').filter(Boolean); // Remove empty parts
        return pathParts[0] === 'campaign' && pathParts.length === 2;
    };

    // Pages that should not display the sidebar
    const excludeSidebar = ['/login', '/register', '/', '/contact', '/campaigns', '/about'];

    // Extend the condition to exclude sidebar on campaign detail pages
    const showSidebar = !excludeSidebar.includes(location.pathname) && 
                        !isCampaignDetailPage(location.pathname) &&
                        currentUser;

    return (
        <nav className="navbar">
            {!showSidebar && (
                <>
                    <span className='logo'>
                        <img src='/logo.png' alt='HarambeeHub' height='50px' width='50px' />
                        <p><b>HarambeeHub</b></p>
                    </span>
                </>
            )}{/*
            <div className={`nav-links ${isSidebarOpen ? '' : 'sidebar-hidden'}`}>
                {!showSidebar && (
                    <>
                        <Link to="/">Home</Link>
                        <Link to="/about">About</Link>
                        <Link to="/contact">Contacts</Link>
                        <Link to="/campaigns">Campaigns</Link>
                    </>
                )}
                {!showSidebar && currentUser && (
                    <>
                        <Link to="/dashboard">Dashboard</Link>
                    </>
                )}
            </div>*/}
            <div className="user-section">
                {currentUser ? (
                    <div ref={dropdownRef} onClick={toggleDropdown}>
                        <div className="navbar-user-icon">
                            <img src={currentUser ? currentUser.profilePic || '/images/profile.png' : '/images/profile.png'} alt="User" />
                        </div>
                        {showDropdown && (
                            <div className="navbar-dropdown">
                                <Link to="/profile" className="dropdown-item">{currentUser.name}</Link>
                                <Link to="/dashboard" className="dropdown-item">Dashboard</Link>
                                <div className="dropdown-item" onClick={logout}>Log Out</div>
                            </div>
                        )}
                    </div>
                    ) : (
                        <div className='accounts'>
                            <Link to="/">Home</Link>
                            <Link to="/login">Login</Link>
                            {/*<Link to="/register">Register</Link>*/}
                        </div>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
