import React, { useState, useEffect } from 'react';
import CampaignList from '../../components/CampaignList/CampaignList';
import './AllCampaignsPage.css';
import { API_URL } from '../../config/Constants';

function AllCampaignsPage() {
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await fetch(API_URL+'/campaigns');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCampaigns(data);
            } catch (error) {
                console.error("There was a problem with the fetch operation:", error);
            }
        };

        fetchCampaigns();
    }, []);

    return (
        <div className="all-campaigns-page">
            <h1>Explore Campaigns</h1>
            <CampaignList campaigns={campaigns} />
        </div>
    );
}

export default AllCampaignsPage;
