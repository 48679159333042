import React, { useState } from 'react';
import RegisterForm from './RegisterForm';
import { Link, useNavigate } from 'react-router-dom';
import './RegisterPage.css';
import { API_URL } from '../../config/Constants';

function RegisterPage() {
    const navigate = useNavigate();
    const [message, setMessage] = useState(''); // State to store feedback message
    const [status, setStatus] = useState(false);

    const handleRegistration = async (registerData) => {
        setMessage(''); // Reset message
        setStatus(false); // Reset status
        try {
            const response = await fetch(API_URL+'/users/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(registerData),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to register');
            }

            setMessage('Registration successful! Redirecting to login...');
            setStatus(true);
            setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
        } catch (error) {
            setMessage(error.message || 'Registration failed. Please try again.');
        }
    };

    return (
        <div className="register-page">
            <h2>Create Your Account</h2>
            {message && <p className={status ? "success" : "error"}>{message}</p>}
            <RegisterForm onRegister={handleRegistration} />
            <p>
                Already have an account? <Link to="/login">Login here</Link>
            </p>
        </div>
    );
}

export default RegisterPage;
