import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './CampaignPage.css';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../config/Constants';
import DonationForm from '../../components/DonationForm/DonationForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faShareAlt, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function CampaignPage() {
    let { id } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [contributors, setContributors] = useState([]);
    const [comments, setComments] = useState([]);
    const [text, setComment] = useState([]);
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [isUserCampaignOwner, setCampaignOwner] = useState(false);

    // Fetch campaign details
    useEffect(() => {
        const fetchCampaignDetails = async () => {
            try {
                const response = await fetch(`${API_URL}/campaigns/${id}`);
                if (!response.ok) {
                    throw new Error('Could not fetch campaign details');
                }
                const data = await response.json();
                setCampaign(data);
            } catch (error) {
                console.error("Error fetching campaign details:", error);
            }
        };

        fetchCampaignDetails();
    }, [id]);

    // Fetch contributors if logged in
    useEffect(() => {
        const fetchContributors = async () => {
            if (currentUser) {
                try {
                    const response = await fetch(`${API_URL}/campaigns/${id}/contributors`);
                    if (!response.ok) {
                        throw new Error('Could not fetch contributors');
                    }
                    const data = await response.json();
                    setContributors(data);
                } catch (error) {
                    console.error("Error fetching contributors:", error);
                }
            }
        };

        fetchContributors();
    }, [currentUser, id]);

    // Fetch comments
    useEffect(() => {
        const fetchComments = async () => {
            try {
                const response = await fetch(`${API_URL}/comments/${id}`);
                if (!response.ok) {
                    throw new Error('Could not fetch comments');
                }
                const data = await response.json();
                setComments(data);
            } catch (error) {
                console.error("Error fetching comments:", error);
            }
        };

        fetchComments();
    }, [id]);

    // set campaign owner
    useEffect(() => {
        if (currentUser && campaign) {
            if (campaign.createdBy._id === currentUser._id) {
                setCampaignOwner(true);
            }            
        }
    
    }, [currentUser, campaign]);

    // Render function for campaign comments
    const renderComments = () => comments.map((comment, index) => (
        <div key={index} className="comment">
            <strong>{comment.author.name}</strong>
            <p>{comment.text}</p>
        </div>
    ));

    const handleEdit = () => {
        if (isUserCampaignOwner) {
            navigate(`/campaigns/edit/${id}`);
            return;
        }
    };
      
    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this campaign?")) {
          try {
            const response = await fetch(`${API_URL}/campaigns/${id}`, {
              method: 'DELETE',
              headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
            });
      
            if (!response.ok) throw new Error('Failed to delete the campaign');
      
            console.log("Campaign deleted successfully");
            navigate(`/campaigns`);
          } catch (error) {
            console.error("Deletion error:", error);
            alert('Failed to delete the campaign');
          }
        }
    };
      
    const handleShare = () => {
        const campaignUrl = window.location.href;
        navigator.clipboard.writeText(campaignUrl)
          .then(() => alert("Campaign URL copied to clipboard!"))
          .catch((error) => console.error("Copy failed", error));
    };
      
    const handleReport = () => {
        // Example with a simple prompt; consider a modal/form for detailed reporting
        const reason = window.prompt("Please enter your reason for reporting this campaign:");
        if (reason) {
          // Submit `reason` to your API
          console.log("Report submitted: ", reason);
          // Additional logic to handle the submission result
        }
    };    

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!text.trim()) return;

        try {
            const response = await fetch(`${API_URL}/comments/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ text })
            });

            if (!response.ok) {
                throw new Error('Failed to post comment');
            }

            const newComment = await response.json();
            setComments([...comments, newComment]);
            setComment(''); // Clear the input after successful submission
        } catch (error) {
            console.error("Error posting comment:", error);
        }
    };

    if (!campaign) {
        return <div>Loading campaign details...</div>;
    }
    
    return (
        <div className="campaign-page">
            <div className="campaign-content">
                <h1>{campaign.title}</h1>
                <img src={campaign.image} alt={campaign.title} className="campaign-image"/>
                <p>{campaign.description}</p>
                <div className="campaign-progress">
                    <progress value={campaign.currentAmount} max={campaign.goalAmount}></progress>
                    <span>{`${new Intl.NumberFormat('en-TZ', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(campaign.currentAmount)} / ${new Intl.NumberFormat('en-TZ', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(campaign.goalAmount)}`}</span>
                </div>
                <div className="campaign-story">
                    <div className="campaign-info">
                        <p className="campaign-creator">Created by: {campaign.createdBy.name}</p>
                        <span className="campaign-date">
                          {'End: '}{new Date(campaign.endDate).toLocaleDateString('en-US')}
                        </span>
                    </div>
                    <div className="campaign-actions">
                        <button aria-label="Share" title='Share' onClick={handleShare}><FontAwesomeIcon icon={faShareAlt} /></button>
                        <button aria-label="Report" title='Report' onClick={handleReport}><FontAwesomeIcon icon={faExclamationTriangle} /></button>
                        {isUserCampaignOwner && (
                            <>
                                <button aria-label="Edit" title='Edit' onClick={handleEdit}><FontAwesomeIcon icon={faEdit} /></button>
                                <button aria-label="Delete" title='Delete' onClick={handleDelete}><FontAwesomeIcon icon={faTrashAlt} /></button>
                            </>
                        )}
                    </div>
                </div>

                <section className="campaign-comments">
                    <h2>Comments</h2>
                    {currentUser && (
                        <form onSubmit={handleCommentSubmit}>
                            <textarea
                                value={text}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Leave a comment"
                                className="comment"
                                required
                            ></textarea>
                            <button type="submit">Submit</button>
                        </form>
                    )}
                    {renderComments()}
                </section>
            </div>

            <div className="right-column">
                <DonationForm campaignId={id} />

                {currentUser && (
                    <section className="campaign-contributors">
                        <h2>Contributors</h2>
                        <ul>
                            {contributors.map((contributor, index) => (
                                <li key={index}>
                                    {contributor.donorName} - Tsh{' '}
                                    {new Intl.NumberFormat('en-TZ', {
                                        style: 'decimal',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    }).format(contributor.amount)}
                                </li>
                            ))}
                        </ul>
                    </section>
                )}
            </div>
        </div>
    );
}

export default CampaignPage;
