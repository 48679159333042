import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function Sidebar() {
    const { currentUser } = useAuth();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Function to determine if the path is for an individual campaign page
    const isCampaignDetailPage = (pathname) => {
        // Check if path starts with '/campaigns/' and has an ID part
        const pathParts = pathname.split('/').filter(Boolean); // Remove empty parts
        return pathParts[0] === 'campaign' && pathParts.length === 2;
    };

    // Pages that should not display the sidebar
    const excludeSidebar = ['/login', '/register', '/', '/contact', '/campaigns', '/about'];

    // Extend the condition to exclude sidebar on campaign detail pages
    const showSidebar = !excludeSidebar.includes(location.pathname) && 
                        !isCampaignDetailPage(location.pathname) &&
                        currentUser;

    return (
        <>
            {showSidebar && (
                <div>
                    <aside className={`sidebar ${isSidebarOpen ? '' : 'sidebar-hidden'}`}>
                        <img src='/logo.png' alt='HarambeeHub' height='100px' width='130px' />
                        <NavLink to="/" activeClassName="active">Home</NavLink>
                        <NavLink to="/dashboard" activeClassName="active">Dashboard</NavLink>
                        {/*<NavLink to="/campaigns" activeClassName="active">Campaigns</NavLink>*/}
                        <NavLink to="/campaigns/user" activeClassName="active">My Campaigns</NavLink>
                        <NavLink to="/campaigns/create" activeClassName="active">Create Campaign</NavLink>
                        <NavLink to="/profile" activeClassName="active">Profile</NavLink>
                        <NavLink to="/settings" activeClassName="active">Settings</NavLink>
                    </aside>

                    <button className="menu-toggle" onClick={toggleSidebar} aria-label="Toggle menu">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
            )}
        </>
    );
}

export default Sidebar;
