import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DonationCard from '../../components/DonationCard/DonationCard';
import './HomePage.css';
import { API_URL } from '../../config/Constants';

function HomePage() {
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const response = await fetch(API_URL+'/campaigns');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCampaigns(data);
            } catch (error) {
                console.error("There was a problem with the fetch operation:", error);
            }
        };

        fetchCampaigns();
    }, []);

    return (
        <div className="homepage">
            <section className="hero-section"  style={{ backgroundImage: `url("/images/hands.webp")` }}>
                <h1>Welcome to Harambee Hub</h1>
                <p>Empowering communities through collaborative funding. Join us in making a difference.</p>
                <a href="#featured-campaigns" className="explore-button">Explore Campaigns</a>
            </section>

            <section id="featured-campaigns" className="featured-campaigns">
                <h2>Featured Campaigns</h2>
                <div className="campaigns-grid">
                    {campaigns.slice(0, 4).map(campaign => (
                        <DonationCard key={campaign.id} campaign={campaign} />
                    ))}
                </div>
                <Link to="/campaigns" className="view-all">View All Campaigns</Link>
            </section>

            <section className="how-it-works">
                <h2>How It Works</h2>
                <div className="steps-container">
                    <div className="step">
                        <h3>1. Start a Campaign</h3>
                        <p>Create your campaign, tell your story, and set your goal.</p>
                    </div>
                    <div className="step">
                        <h3>2. Share with Community</h3>
                        <p>Spread the word about your campaign across your network and beyond.</p>
                    </div>
                    <div className="step">
                        <h3>3. Receive Donations</h3>
                        <p>Collect contributions from supporters all over the world.</p>
                    </div>
                    <div className="step">
                        <h3>4. Make an Impact</h3>
                        <p>Use the funds raised to make a real difference in your community.</p>
                    </div>
                </div>
                <Link to="/campaigns/create" className="start-campaign-button">Start Your Campaign</Link>
            </section>
{/*
            <section className="success-stories">
                <h2>Success Stories</h2>
                <div className="stories-grid">
                    <div className="story">
                        <h3>School Supplies for Rural Areas</h3>
                        <p>Raised $10,000 to provide books and materials for local schools.</p>
                    </div>
                    <div className="story">
                        <h3>Community Health Center Upgrade</h3>
                        <p>Achieved a $15,000 goal to upgrade medical equipment.</p>
                    </div>
                    <div className="story">
                        <h3>Emergency Relief Fund</h3>
                        <p>Collected $20,000 in emergency funds for flood relief efforts.</p>
                    </div>
                </div>
            </section>

            <section className="testimonials">
                <h2>Testimonials</h2>
                <div className="testimonials-container">
                    <div className="testimonial">
                        <p>“Thanks to Harambee Hub, we could fund our community project rapidly and efficiently.” - John Doe</p>
                    </div>
                    <div className="testimonial">
                        <p>“This platform has connected us to supporters we never knew we had. Truly life-changing!” - Jane Smith</p>
                    </div>
                    <div className="testimonial">
                        <p>“Seeing our community come together to support our cause was incredible. Heartfelt thanks to all donors.” - Ahmed Ali</p>
                    </div>
                </div>
                </section>*/}
        </div>
    );
}

export default HomePage;
