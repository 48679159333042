import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext'; // Adjust based on your auth management
import './UserProfilePage.css';
import { API_URL } from '../../config/Constants';

function UserProfilePage() {
    const { currentUser } = useAuth();
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        nin: '',
        phone: '',
        password: '',
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${API_URL}/users/profile`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch profile');
                }
                const data = await response.json();
                setProfile(data);
            } catch (error) {
                console.error("Error fetching user profile:", error);
                setMessage('Failed to load profile data.');
            }
        };

        if (currentUser) {
            fetchUserProfile();
        }
    }, [currentUser]);

    const handleInputChange = (e) => {
        setProfile({ ...profile, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');

        try {
            const response = await fetch(`${API_URL}/users/profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(profile)
            });

            if (!response.ok) {
                throw new Error('Failed to update profile');
            }

            setMessage('Profile updated successfully!');
        } catch (error) {
            console.error("Error updating profile:", error);
            setMessage('Failed to update profile.');
        }
    };

    return (
        <div className="user-profile-page">
            <div className="user-details">
                <h2>User Profile</h2>
                <div className="detail">
                    <label>Name:</label>
                    <input type="text" name="name" value={profile.name} onChange={handleInputChange} />
                </div>
                <div className="detail">
                    <label>Phone:</label>
                    <input type="text" name="phone" value={profile.phone} onChange={handleInputChange} />
                </div>
                <div className="detail">
                    <label>NIDA:</label>
                    <input type="text" name="nin" value={profile.nin} onChange={handleInputChange} />
                </div>
                <div className="detail">
                    <label>Email:</label>
                    <input type="email" name="email" value={profile.email} onChange={handleInputChange} disabled />
                </div>
                <div className="detail">
                    <label>Password:</label>
                    <input type="password" name="password" value={profile.password} onChange={handleInputChange} />
                </div>
                <button onClick={handleSubmit}>Save Changes</button>
            </div>
            {message && <p>{message}</p>}
        </div>
    );
}

export default UserProfilePage;