import React, { useState, useEffect } from 'react';
import './CreateCampaignPage.css';
import { useNavigate, useParams } from 'react-router-dom';
import CampaignForm from '../../components/CampaignForm/CampaignForm';
import { API_URL } from '../../config/Constants';

const EditCampaignPage = () => {
    let { id } = useParams();
    const [campaign, setCampaign] = useState(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        // Fetch the campaign details to pre-fill the form
        const fetchCampaignDetails = async () => {
            const response = await fetch(`${API_URL}/campaigns/${id}`);
            const data = await response.json();
            setCampaign(data);
        };

        fetchCampaignDetails();
    }, [id]);

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [navigate, token]);

    const handleSubmit = async (campaignData) => {
        // Create an instance of FormData
        const formData = new FormData();
    
        // Append each campaignData property to formData.
        for (const key in campaignData) {
            if (campaignData.hasOwnProperty(key)) {
                formData.append(key, campaignData[key]);
            }
        }
        try {
            const response = await fetch(`${API_URL}/campaigns/${id}`, {
                method: 'PUT',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formData,
            });

            if (!response.ok) throw new Error('Could not update the campaign');

            alert('Campaign updated successfully');
            navigate(`/campaign/${id}`);
        } catch (error) {
            console.error(error);
            alert('Failed to update the campaign');
        }
    };

    return (
        <div className="create-campaign-page">
            <h1>Edit Campaign</h1>
            <CampaignForm initialData={campaign} onSubmit={handleSubmit}/>
        </div>
    );
};

export default EditCampaignPage;
