import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

const Footer = () => {
  const { currentUser } = useAuth();
  const location = useLocation();

  // Function to determine if the path is for an individual campaign page
  const isCampaignDetailPage = (pathname) => {
      // Check if path starts with '/campaigns/' and has an ID part
      const pathParts = pathname.split('/').filter(Boolean); // Remove empty parts
      return pathParts[0] === 'campaign' && pathParts.length === 2;
  };

  // Pages that should not display the sidebar
  const excludeSidebar = ['/login', '/register', '/', '/contact', '/campaigns', '/about'];

  // Extend the condition to exclude sidebar on campaign detail pages
  const showSidebar = !excludeSidebar.includes(location.pathname) && 
                      !isCampaignDetailPage(location.pathname) &&
                      currentUser;
                      
  return (
    <footer className="site-footer">
    {!showSidebar && (
      <div className="footer-content">
        <div className="footer-section">
          <h4>Harambee Hub</h4>
          <img src="/logo.png" alt="Company Logo" className="footer-logo" height={100} width={150} />
        </div>
        <div className="footer-section">
            <h4>Contact us</h4>
            <div className="contact-icons">
                <div><FontAwesomeIcon icon={faPhone} className='icon' /> +255 693 692 847</div>
                <div><FontAwesomeIcon icon={faEnvelope} className='icon' /> info@harambeehub.com</div>
                <div><FontAwesomeIcon icon={faHome} className='icon' /> Mbezi Beach A, Dar es Salaam, Tz</div>
            </div>
        </div>
        <div className="footer-section">
            <h4>Quick Links</h4>
            <div className="quick-links">
                <div><Link to="/" className="links">Home</Link></div>
                <div><Link to="/campaigns" className="links">Campaigns</Link></div>
                <div><Link to="/login" className="links">Login</Link></div>
            </div>
        </div>
        <div className="footer-section">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a href="https://twitter.com" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
              <a href="https://facebook.com" aria-label="Facebook"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a href="https://instagram.com" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
            </div>
        </div>
      </div>
    )}
      <div className="footer-bottom">
        <p><span className='icon'>&copy; </span>{new Date().getFullYear()} <a href='http://huksinnovation.co.tz' target='blank'>Huks Innovation Limited</a>. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;