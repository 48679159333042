import React, { useState, useEffect } from 'react';
import './CampaignForm.css';

function CampaignForm({ initialData, onSubmit }) {
    const [campaignData, setCampaignData] = useState({
        title: '',
        description: '',
        goalAmount: '',
        category: '',
        endDate: '',
        image: null,
    });
    const [submitStatus, setSubmitStatus] = useState({ success: false, message: '' });

    // Assuming `campaignData.endDate` is a JavaScript Date object
    const formatDateForInput = (date) => {
        if (!date) return '';

        const d = new Date(date);
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    };
    
    useEffect(() => {
        if (initialData) {
            const formattedInitialData = {
                ...initialData,
                endDate: formatDateForInput(initialData.endDate)
            };
            setCampaignData(formattedInitialData);
        }
    }, [initialData]);

    const validateForm = () => {
        // Basic validation logic
        if (!campaignData.title || !campaignData.description || !campaignData.goalAmount || !campaignData.category || !campaignData.endDate) {
            setSubmitStatus({ success: false, message: 'Please fill in all required fields.' });
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCampaignData({ ...campaignData, [name]: value });
    };

    const handleImageChange = (e) => {
        if (e.target.name === 'image') {
            setCampaignData({ ...campaignData, image: e.target.files[0] });
        } else {
            setCampaignData({ ...campaignData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        onSubmit(campaignData);
    };

    return (
        <div>
            <form className="campaign-form" onSubmit={handleSubmit}>
                <label htmlFor="title">Title</label>
                <input
                    type="text"
                    id="title"
                    name="title"
                    value={campaignData.title}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="description">Description</label>
                <textarea
                    id="description"
                    name="description"
                    value={campaignData.description}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="goalAmount">Goal Amount (TZS)</label>
                <input
                    type="number"
                    id="goalAmount"
                    name="goalAmount"
                    value={campaignData.goalAmount}
                    onChange={handleChange}
                    required
                />

                <label htmlFor="category">Category</label>
                <select
                    id="category"
                    name="category"
                    value={campaignData.category}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select a category</option>
                    <option value="charity">Charity</option>
                    <option value="education">Education</option>
                    <option value="events">Events</option>
                    <option value="funeral">Funeral</option>
                    <option value="health">Health</option>
                    <option value="personal">Personal</option>
                    <option value="religion">Religion</option>
                    <option value="sports">Sports</option>
                    <option value="wedding">Wedding</option>
                </select>

                <label htmlFor="endDate">End Date:</label>
                <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={campaignData.endDate}
                    onChange={handleChange}
                />

                <label htmlFor="image">Image</label>
                <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={handleImageChange}
                    accept="image/*"
                />

                <button type="submit">Submit</button>
            </form>

            {submitStatus.message && (
                <div className={`submit-feedback ${submitStatus.success ? 'success' : 'error'}`}>
                    {submitStatus.message}
                </div>
            )}
        </div>
    );
}

export default CampaignForm;
