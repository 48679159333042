import React, { useState, useEffect } from 'react';
import './CreateCampaignPage.css';
import { useNavigate } from 'react-router-dom';
import CampaignForm from '../../components/CampaignForm/CampaignForm';
import { useAuth } from '../../context/AuthContext';
import { API_URL } from '../../config/Constants';

function CreateCampaignPage() {
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [status, setStatus] = useState(false);
    const token = localStorage.getItem('token');
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            navigate('/login');
            return;
        }
    }, [navigate, currentUser]);

    const handleSubmit = async (campaignData) => {
        setMessage(''); // Clear previous messages
        setStatus(false); // Reset status
    
        // Create an instance of FormData
        const formData = new FormData();
    
        // Append each campaignData property to formData.
        for (const key in campaignData) {
            if (campaignData.hasOwnProperty(key)) {
                formData.append(key, campaignData[key]);
            }
        }
    
        try {
            const response = await fetch(API_URL+'/campaigns/', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formData,
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                throw new Error(data.message || 'Failed to create Campaign');
            }
    
            setMessage('Campaign created successfully!');
            setStatus(true);
            setTimeout(() => navigate('/campaign/'+data._id), 100);
        } catch (error) {
            setMessage(error.message || 'Campaign creation failed. Please try again.');
        }
    };    

    return (
        <div className="create-campaign-page">
            <h1>Create a New Campaign</h1>
            {message && <p className={status ? "success" : "error"}>{message}</p>}
            <CampaignForm onSubmit={handleSubmit}/>
        </div>
    );
}

export default CreateCampaignPage;
