import React from 'react';
import { Link } from 'react-router-dom';
import './DonationCard.css';

function DonationCard({ campaign }) {
    return (
        <div className="donation-card">
            <img src={campaign.image} alt={campaign.title} className="campaign-image"/>
            <div className="campaign-details">
                <h3>
                    <Link to={`/campaign/${campaign._id}`} className="campaign-title-link">
                        {campaign.title}
                    </Link>
                </h3>
                <p className="campaign-creator">Created by: {campaign.createdBy.name}</p>
                <p>{campaign.description}</p>
                <div className="campaign-progress">
                    <progress value={campaign.currentAmount} max={campaign.goalAmount}></progress>
                    <span className="campaign-date">
                        {'End: '}{new Date(campaign.endDate).toLocaleDateString('en-US')}
                    </span>
                    <span className="campaign-amount">{`${new Intl.NumberFormat('en-TZ', {
                                        style: 'decimal',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                    }).format(campaign.currentAmount)} / ${new Intl.NumberFormat('en-TZ', {
                                        style: 'decimal',
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 2,
                                    }).format(campaign.goalAmount)}`}</span>
                </div>
                    <Link to={`/campaign/${campaign._id}`} className="donate-button">
                        Donate Now
                    </Link>
            </div>
        </div>
    );
}

export default DonationCard;
